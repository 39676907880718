@import '@assets/styles';

.registration-form {
  background-color: $COLOR_WHITE;
  padding: $space-5;
  margin-top: $space-5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $COLOR_GRAY_300;
  width: 100%;

  &__fields {
    width: 100%;

    &--email {
      margin-top: $space-3;
    }

    &--confirm-email {
      margin-top: $space-3;
    }

    &--password {
      margin-top: $space-3;
    }
  }

  &__password-actions {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include screen-md {
      flex-direction: row;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: $space-4;
    width: 100%;
    margin-top: $space-4;

    @include screen-md {
      flex-direction: row;
    }
  }
}