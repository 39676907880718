@import '@assets/styles';

.error-section {
  display: flex;
  align-items: center;
  gap: $space-2;
  width: 100%;
  padding: $space-2 $space-4;
  border-radius: 2px;
  margin: $space-4 0 $space-3 0;
  background: $COLOR_WHITE;
  border-left: 3px solid $COLOR_WARNING;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);

  & > p {
    font-weight: $font-weight-semi-bold;
    line-height: $space-4;
    color: $COLOR_WARNING;
  }
}