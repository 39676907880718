@import '../../../assets/styles';

.common-link {
  color: $COLOR_DARK_GREEN;
  cursor: pointer;
  font-weight: $font-weight-bold;

  &--primary {
    color: $COLOR_DARK_GREEN;

    &:hover {
      color: $COLOR_DARK_GREEN;
    }
  }

  &--warning {
    color: $COLOR_WARNING;
    
    &:hover {
      color: $COLOR_WARNING;
    }
  }
}