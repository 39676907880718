@import '../../../assets/styles';

.checkbox-wrapper {
  width: 100%;
  display: flex;
  margin-top: $space-3;

  &>input[type=checkbox] {
    width: 24px;
    height: 24px;
    border: 1px solid $COLOR_GRAY_DARK;
    border-radius: 50%;
    accent-color: $COLOR_GRAY_DARK;
    outline: none;
    cursor: pointer;
  }
}

.checkmark {
  margin-left: $space-1;
}