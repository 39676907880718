 * {
   box-sizing: border-box;
   font-family: $font-main;
 }

 html,
 body {
   font-size: 16px;
   color: $COLOR_BLACK;
   background-color: $COLOR_GRAY_100;
   margin: 0;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 ul,
 ol,
 li,
 p {
   margin: 0;
   padding: 0;
 }

 ol,
 ul {
   list-style: none;
 }

 button {
   cursor: pointer;
 }

 input,
 select,
 button {
   font-family: inherit;
   font-size: inherit;
 }

 .main-container {
   width: 100%;
   display: flex;
   justify-content: center;
   max-width: $container-sm;
   margin: 0 auto;

   min-height: calc(100dvh - 182px); // AEM header + footer min.height

   padding-top: $space-4;
   padding-bottom: $space-6;

   @include screen-sm {
     max-width: $container-md;
   }

   @include screen-md {
     max-width: $container-md;
   }

   @include screen-lg {
     max-width: $container-lg;
   }

   @include screen-xl {
     max-width: $container-xl;
   }
 }

 .d-flex {
   display: flex;
 }

 .flex-column {
   flex-direction: column;
 }
