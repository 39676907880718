/** These are mixins for mobile-first approach */
@import 'variables';

@mixin screen-sm {
  @media (min-width: $screen-sm) {
    @content;
  }
}

@mixin screen-md {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin screen-lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin screen-xl {
  @media (min-width: $screen-xl) {
    @content;
  }
}