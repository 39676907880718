// screen-sizes
$screen-sm: 375px;
$screen-md: 790px;
$screen-lg: 1440px;
$screen-xl: 1920px;
// container-sizes
$container-sm: 327px;
$container-md: 768px;
$container-lg: 1260px;
$container-xl: 1600px;
// space sizes
$space-1: 8px;
$space-2: 16px;
$space-3: 24px;
$space-4: 32px;
$space-5: 40px;
$space-6: 48px;
// font weights
$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 100;