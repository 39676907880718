@import '../../../assets/styles';

.common-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &__children {
    padding-left: $space-1;
    margin-top: $space-1;
  }

  &__error-message {
    padding-left: $space-1;
    color: $COLOR_WARNING;
    margin-top: $space-1;
    font-weight: $font-weight-semi-bold;
  }

  &__label {
    margin-bottom: $space-1;
    font-weight: $font-weight-semi-bold;
    color: $COLOR_GRAY_DARK;
  }

  &>input {
    padding: 4px 60px 4px 12px;
    height: 40px;
    width: 100%;
    border-radius: 2px;
    border: 2px solid $COLOR_GRAY_300;

    &.error {
      background: url('../../../assets/images/warningIcon.svg') no-repeat right;
      background-position: right 7% center;
      border: 2px solid $COLOR_WARNING;
    }
  }

  &__error {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 50%;
    border: none;
    background-color: inherit;
  }
}