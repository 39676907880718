@import 'variables';

html {
  line-height: 2;
  font-weight: $font-weight-regular;
  letter-spacing: -0.005em;
  color: $COLOR_GRAY_DARK;
}

h1,
h2,
h3 {
  font-weight: $font-weight-bold;
  margin: 0 0 $space-2;
}

h1 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.32px;
}

h2 {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.18px;

  @include screen-md {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.12px;
  }
}

.text-subtitle-1 {
  font-size: 16px;
  line-height: 24px;
}

.text-subtitle-2 {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.07px;
}

.text-title-1 {
  font-size: 24px;
  line-height: 36px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.12px;
}

.text-body-1 {
  font-size: 16px;
  line-height: 24px;
}

.text-body-2 {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.07px;
}

.text-body-3 {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03px;
}

.text-button-1 {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.14px;
}