@import '../../../assets/styles';

.common-button {
  width: 100%;
  display: flex;
  align-items: center;

  &.tertiary > &__title {
    margin-left: $space-1;
  }

  &--loading {
    opacity: 0.5;
  }
}

.small {
  padding: $space-1 $space-4;
  font-weight: $font-weight-semi-bold;
}

.regular {
  padding: $space-2 $space-4;

  @include screen-md {
    padding: $space-2 $space-1;
  }

  @include screen-lg {
    padding: $space-2 $space-4;
  }
}

.primary {
  justify-content: center;
  background-color: $COLOR_DARK_GREEN;
  color: $COLOR_WHITE;
  font-weight: $font-weight-bold;
  border-radius: 2px;
  border: 1px solid $COLOR_DARK_GREEN;
}

.secondary {
  justify-content: center;
  background-color: $COLOR_WHITE;
  color: $COLOR_DARK_GREEN;
  font-weight: $font-weight-bold;
  border-radius: 2px;
  border: 1px solid $COLOR_DARK_GREEN;
}

.tertiary {
  color: $COLOR_DARK_GREEN;
  font-weight: $font-weight-semi-bold;
  border: none;
  padding: 0;
  background-color: transparent;
}
