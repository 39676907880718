@import '@assets/styles';

.turnstile {
  margin-top: $space-3;

  &__title {
    font-weight: $font-weight-semi-bold;
    
  }

  &__captcha {
    margin-top: $space-1;
  }
}