@import '@assets/styles';

.info-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include screen-md {
    flex-direction: row;
    justify-content: start;
    margin-left: $space-2;
  }

  &__icon {
    @include screen-md {
      margin-right: $space-3;
      width: 60px;
      height: 50px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-md {
      align-items: flex-start;
    }
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    margin-top: $space-2;

    @include screen-md {
      margin-top: 0;
    }
  }

  &__subtitle {
    margin-top: $space-1;
  }
}