@import '@assets/styles';

.registration-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 $space-3;

  @include screen-md {
    align-items: flex-start;
    max-width: 494px;
    padding: 0;
  }

  @include screen-lg {
    max-width: 611px;
  }

  @include screen-xl {
    max-width: 776px;
  }
}
