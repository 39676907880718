$COLOR_BLACK: #000000;
$COLOR_WHITE: #ffffff;
$COLOR_OCEAN: #009ca9;
$COLOR_GRAY_DARK: #313131;
$COLOR_GRAY_100: #F5F5F5;
$COLOR_GRAY_200: #EAEAEA;
$COLOR_GRAY_300: #D6D6D6;
$COLOR_GRAY_400: #ADADAD;
$COLOR_GRAY_6005: #5A5A5A;
$COLOR_DARK_GREEN: #115C36;
$COLOR_MEDIUM_BLUE: #1B5BA0;
$COLOR_WARNING: #C73636;
$COLOR_GREEN: #008707;
