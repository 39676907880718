@import '@assets/styles';

.validation-rules__item {
  &.valid {
    color: $COLOR_GREEN;
  }

  &.not-valid {
    color: $COLOR_WARNING;
  }

  &-list {
    margin-left: $space-1;
    list-style: inside;
    color: $COLOR_GRAY_DARK;

    &__item.valid {
      color: $COLOR_GREEN;
    }
  }
}